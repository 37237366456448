import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "enHome",
  "customerSection": {
    "title": "test",
    "customers": [{
      "logo": "/img/head.png",
      "path": "test",
      "size": 50
    }]
  },
  "seo": {
    "title": "Home",
    "descr": "-"
  },
  "hero": {
    "heroBackground": "/img/homehero.webp",
    "brand": "/img/zimply-logo-color.webp",
    "logo": "/img/zimply-text-white.webp",
    "heroText": "Automate with digital assistants. Get rid of time consuming tasks",
    "textRotator": [{
      "text": "Human"
    }, {
      "text": "Automation"
    }, {
      "text": "More Time"
    }, {
      "text": "Your Way"
    }, {
      "text": "More Fun"
    }],
    "heroButtonText": "Create your assistant",
    "heroButtonPath": "createassistant"
  },
  "videoSection": {
    "videoUrl": "https://player.vimeo.com/video/598866568?h=ba0b099060",
    "title": "See how it works",
    "text": "Take back your time. Zimply’s digital assistants are here to help. Automate your daily, repetitive tasks with our digital assistants so you can focus on what really matters. We provide automation that will change your company for the better.",
    "buttonText": "Book demo",
    "buttonPath": "/demo"
  },
  "sellingPoints": {
    "title": "How to get started",
    "arrow": "/img/arrow-right.webp",
    "points": [{
      "title": "Zimplify your work",
      "text": "Do you need more time? Do you want to focus on more valuable tasks? Zimply will help you find a more efficient way of working while automating your processes."
    }, {
      "title": "Let's do some magic",
      "text": "We teach your Digital Assistant how to automate the chosen process while also improving and optimizing the process."
    }, {
      "title": "The AI-assistant is live!",
      "text": "You can now enjoy working on other tasks that you like doing and that are better for your company"
    }],
    "robotHead": "/img/head.webp"
  },
  "customerTitle": "Selection of our customers",
  "squares": {
    "text1": "We train and hire out Digital Assistants that automate and relieve you from time-consuming and repetitive tasks.",
    "text2": "Are you ready to change your career? We plan long-term and continuously consider and adapt to your personal goals, together we plan for a bright future ahead.",
    "bg1": "/img/pink1.webp",
    "bg2": "/img/pink1.webp",
    "buttonText1": "Learn more",
    "buttonText2": "Learn more",
    "image1": "/img/office2.webp",
    "image2": "/img/office1.webp",
    "title1": "About us",
    "title2": "Career"
  },
  "endSection": {
    "title": "Learn more",
    "icon": "/img/symbol-rosa-glödlampa.webp",
    "buttonText": "Contact us",
    "buttonPath": "contact"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      